import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import fleekLogo from '../assets/fleek.png';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const portalConfig = JSON.parse(localStorage.getItem('portalConfig'));

  const handleLogoClick = () => {
    navigate('/partnermarketplace');
  };

  return (
    <nav style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      backgroundColor: '#1a1d24',
      padding: '12px 24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      zIndex: 1000
    }}>
      <div 
        onClick={handleLogoClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          cursor: 'pointer'
        }}
      >
        <img 
          src={fleekLogo}
          alt="Fleek Logo" 
          style={{
            height: '32px',
            width: 'auto'
          }}
        />
        <span style={{
          color: '#ffffff',
          fontSize: '20px',
          fontWeight: '500',
          borderLeft: '1px solid rgba(255,255,255,0.2)',
          paddingLeft: '12px'
        }}>
          Partners
        </span>
      </div>

      {portalConfig && (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px'
        }}>
          <span className="partner-name">
            {portalConfig.display_name}
          </span>
          {portalConfig.display_logo_url && (
            <div style={{
              height: '32px',
              display: 'flex',
              alignItems: 'center'
            }}>
              <img
                src={portalConfig.display_logo_url}
                alt="Partner Logo"
                style={{
                  height: '100%',
                  width: 'auto',
                  objectFit: 'contain',
                  borderRadius: '6px'
                }}
              />
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar; 