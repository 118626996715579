import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../App.css';
import { BASE_URL } from '../App';
import Loader from '../components/Loader';

const Processing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const orderResponse = location.state?.orderResponse;
  const [isInitiating, setIsInitiating] = useState(false);
  const hasInitiatedRef = useRef(false);
  const [paymentGateway, setPaymentGateway] = useState(null); // 'razorpay' or 'juspay'
  const [jusPayIframeUrl, setJusPayIframeUrl] = useState('');
  const [jusPaySdkPayload, setJusPaySdkPayload] = useState(null);
  const [showJusPayModal, setShowJusPayModal] = useState(false);
  const [showRetryButton, setShowRetryButton] = useState(false);
  
  console.log('Processing component rendered', {
    orderResponse,
    isInitiating,
    hasInitiated: hasInitiatedRef.current,
    timestamp: new Date().toISOString()
  });

  const orderPlace = () => {
    console.log("Order placed successfully");
    navigate('/partnermarketplace');
  };

  const loadScript = (src) => {
    console.log('Loading Razorpay script');
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        console.log('Razorpay script loaded successfully');
        resolve(true);
      };
      script.onerror = () => {
        console.log('Razorpay script failed to load');
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const initiatePayment = async () => {
    console.log('initiatePayment called', {
      isInitiating,
      hasInitiated: hasInitiatedRef.current,
      orderId: orderResponse?.order_id,
      timestamp: new Date().toISOString()
    });

    if (isInitiating || hasInitiatedRef.current) {
      console.log('Payment initiation already in progress or already initiated, skipping');
      return;
    }
    
    try {
      setIsInitiating(true);
      hasInitiatedRef.current = true;
      setShowRetryButton(false); // Reset retry button state
      console.log('Setting isInitiating to true');
      
      // First call the initiate-portal-payment API
      const savedToken = localStorage.getItem('portalToken');
      console.log('Calling initiate-portal-payment API with order_id:', orderResponse.order_id);
      
      const response = await fetch(`${BASE_URL}/partner/v2/initiate-portal-payment`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${savedToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          order_id: orderResponse.order_id
        })
      });

      const data = await response.json();
      console.log("Full payment initiation response:", data);

      // Check if the status is already CHARGED (JusPay specific)
      if (data.result?.status === "CHARGED") {
        console.log("Payment already completed (CHARGED status). Redirecting to callback page.");
        window.location.href = `/payment-callback?order_id=${orderResponse.order_id}`;
        return;
      }

      // Determine which payment gateway to use based on the response
      if (data.result?.checkout_data) {
        // Razorpay flow
        console.log("Checkout data received for Razorpay:", data.result?.checkout_data);
        setPaymentGateway('razorpay');
        await initiateRazorpayPayment(data.result.checkout_data);
      } else if (data.result?.sdk_payload) {
        // New JusPay flow with SDK payload
        console.log("JusPay SDK payload received:", data.result.sdk_payload);
        setPaymentGateway('juspay');
        setJusPaySdkPayload(data.result.sdk_payload);
        
        // Use web URL from payment_links for iframe
        if (data.result.payment_links?.web) {
          setJusPayIframeUrl(data.result.payment_links.web);
        }
        
        // Delay showing the modal to allow for the animation
        setTimeout(() => {
          setShowJusPayModal(true);
        }, 100);
      } else if (data.result?.payment_links?.iframe) {
        // Legacy JusPay flow with direct iframe URL
        console.log("JusPay iframe URL received:", data.result.payment_links.iframe);
        setPaymentGateway('juspay');
        setJusPayIframeUrl(data.result.payment_links.iframe);
        // Delay showing the modal to allow for the animation
        setTimeout(() => {
          setShowJusPayModal(true);
        }, 100);
      } else {
        throw new Error('Invalid payment gateway data received');
      }
    } catch (error) {
      console.error("Payment initiation failed. Full error:", error);
      console.error("Error message:", error.message);
      console.error("Error stack:", error.stack);
      setIsInitiating(false);
      hasInitiatedRef.current = false;
      setShowRetryButton(true); // Show retry button on error
      console.log('Setting isInitiating back to false due to error');
    }
  };

  const initiateRazorpayPayment = async (checkoutData) => {
    try {
      // Load Razorpay SDK
      console.log('Loading Razorpay SDK');
      const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
      if (!res) {
        throw new Error("Razorpay SDK failed to load");
      }

      // Initialize Razorpay with the received checkout data
      const checkoutOptions = {
        ...checkoutData,
        handler: function (response) {
          console.log("Payment Success:", response);
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature)
          window.location.href = `/payment-callback?order_id=${orderResponse.order_id}`;
        },
        modal: {
          ondismiss: function() {
            console.log("Payment modal dismissed");
            setIsInitiating(false);
            hasInitiatedRef.current = false;
            setShowRetryButton(true); // Show retry button when modal is dismissed
          }
        },
        theme: {
          color: "#61dafb",
        },
      };

      console.log('Final Razorpay options:', {
        key: checkoutOptions.key,
        amount: checkoutOptions.amount,
        currency: checkoutOptions.currency,
        name: checkoutOptions.name,
        description: checkoutOptions.description,
        order_id: checkoutOptions.order_id,
        prefill: checkoutOptions.prefill,
        notes: checkoutOptions.notes,
        // Omitting handler and modal for cleaner logging
      });

      const paymentObject = new window.Razorpay(checkoutOptions);
      console.log('Created Razorpay payment object, opening modal...');
      paymentObject.open();
    } catch (error) {
      console.error("Razorpay payment initiation failed:", error);
      setShowRetryButton(true); // Show retry button on error
      throw error;
    }
  };

  // Function to close the JusPay modal
  const closeJusPayModal = () => {
    setShowJusPayModal(false);
    // Reset payment state after animation completes
    setTimeout(() => {
      setIsInitiating(false);
      hasInitiatedRef.current = false;
      setPaymentGateway(null);
      setJusPayIframeUrl('');
      setJusPaySdkPayload(null);
      setShowRetryButton(true); // Show retry button when modal is closed
    }, 300); // Match this with the CSS transition duration
  };

  // Show retry button after 10 seconds if still processing
  useEffect(() => {
    let timer;
    if (isInitiating && !showRetryButton) {
      timer = setTimeout(() => {
        setShowRetryButton(true);
      }, 10000); // 10 seconds
    }
    
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isInitiating, showRetryButton]);

  useEffect(() => {
    console.log('useEffect triggered', {
      orderResponseId: orderResponse?.order_id,
      isInitiating,
      hasInitiated: hasInitiatedRef.current,
      timestamp: new Date().toISOString()
    });

    if (orderResponse?.order_id && !hasInitiatedRef.current) {
      console.log('Conditions met for payment initiation', {
        hasInitiated: hasInitiatedRef.current,
        isInitiating,
        orderId: orderResponse.order_id
      });
      initiatePayment();
    } else {
      console.log('Skipping payment initiation', {
        hasOrderId: !!orderResponse?.order_id,
        hasInitiated: hasInitiatedRef.current,
        isInitiating
      });
    }

    // Clean up any iframe or resources when component unmounts
    return () => {
      console.log('Cleanup: Component unmounting');
    };
  }, [orderResponse?.order_id]);

  // Function to handle JusPay iframe message events
  const handleJusPayCallback = (event) => {
    // JusPay might send postMessage events to indicate payment status
    console.log("Received message event:", event);
    
    try {
      // Check if the message is from JusPay (you might need to verify the origin)
      if (event.data && typeof event.data === 'string') {
        const data = JSON.parse(event.data);
        
        // Handle JusPay events based on your integration requirements
        if (data.status === 'SUCCESS' || data.status === 'CHARGED') {
          console.log("JusPay payment successful:", data);
          window.location.href = `/payment-callback?order_id=${orderResponse.order_id}`;
        } else if (data.status === 'FAILED' || data.status === 'CANCELLED') {
          console.log("JusPay payment failed/cancelled:", data);
          closeJusPayModal();
        }
      }
    } catch (error) {
      console.error("Error processing JusPay callback:", error);
    }
  };

  // Add event listener for JusPay iframe messages
  useEffect(() => {
    if (paymentGateway === 'juspay') {
      window.addEventListener('message', handleJusPayCallback);
      
      return () => {
        window.removeEventListener('message', handleJusPayCallback);
      };
    }
  }, [paymentGateway]);

  // Load JusPay Hyper SDK if needed
  useEffect(() => {
    if (paymentGateway === 'juspay' && jusPaySdkPayload) {
      // Load JusPay Hyper SDK script if needed
      const loadJusPayScript = async () => {
        try {
          const script = document.createElement('script');
          script.src = 'https://public.releases.juspay.in/hyper-sdk-web/juspay-hyper-sdk-web.umd.js';
          script.async = true;
          script.onload = () => {
            console.log('JusPay Hyper SDK loaded successfully');
          };
          document.body.appendChild(script);
        } catch (error) {
          console.error('Error loading JusPay Hyper SDK:', error);
        }
      };
      
      // Only load if the script isn't already loaded
      if (!window.JuspayHyperSDK) {
        loadJusPayScript();
      }
    }
  }, [paymentGateway, jusPaySdkPayload]);

  return (
    <div className="App">
      <header className="App-header">
        <div style={{
          backgroundColor: '#1a1d24',
          borderRadius: '12px',
          padding: '30px',
          maxWidth: '800px',
          width: '90%',
          margin: '20px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}>
          <h2 style={{ marginBottom: '20px' }}>Processing Order</h2>
          
          {!orderResponse?.order_id ? (
            <div style={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              padding: '20px',
              borderRadius: '8px',
              marginBottom: '20px'
            }}>
              <p style={{ margin: '0 0 20px 0' }}>Order creation failed. Please try again.</p>
              <button
                onClick={() => navigate('/partnermarketplace')}
                style={{
                  backgroundColor: '#61dafb',
                  color: '#1a1d24',
                  border: 'none',
                  borderRadius: '8px',
                  padding: '12px 24px',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Back to Marketplace
              </button>
            </div>
          ) : (
            <div style={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              padding: '20px',
              borderRadius: '8px'
            }}>
              {/* Show loader for Razorpay or while determining gateway */}
              <Loader />
              <p style={{ 
                fontSize: '12px',
                color: '#888',
                marginTop: '10px',
                textAlign: 'center'
              }}>
                Order ID: {orderResponse.order_id}
              </p>
              
              {/* Retry button that appears after 10 seconds or on error */}
              {showRetryButton && (
                <div style={{
                  marginTop: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '10px'
                }}>
                  <p style={{ 
                    fontSize: '14px',
                    color: '#ccc', 
                    textAlign: 'center'
                  }}>
                    Taking longer than expected...
                  </p>
                  <button
                    onClick={() => {
                      setIsInitiating(false);
                      hasInitiatedRef.current = false;
                      initiatePayment();
                    }}
                    style={{
                      backgroundColor: '#00ac9d',
                      color: '#1a1d24',
                      border: 'none',
                      borderRadius: '8px',
                      padding: '12px 24px',
                      fontSize: '16px',
                      cursor: 'pointer',
                      fontWeight: 'bold'
                    }}
                  >
                    Retry Payment
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        {/* JusPay Modal with slide-up animation */}
        {paymentGateway === 'juspay' && jusPayIframeUrl && (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            transition: 'opacity 0.3s ease',
            opacity: showJusPayModal ? 1 : 0,
            pointerEvents: showJusPayModal ? 'auto' : 'none'
          }}>
            <div style={{
              width: '100%',
              maxWidth: '500px',
              height: '80vh',
              backgroundColor: '#1a1d24',
              borderRadius: '16px 16px 0 0',
              overflow: 'hidden',
              boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.3)',
              transition: 'transform 0.3s ease',
              transform: showJusPayModal ? 'translateY(0)' : 'translateY(100%)'
            }}>
              <div style={{
                padding: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)'
              }}>
                <h3 style={{ margin: 0, color: 'white' }}>Complete Payment</h3>
                <button
                  onClick={closeJusPayModal}
                  style={{
                    background: 'transparent',
                    border: 'none',
                    color: 'white',
                    fontSize: '24px',
                    cursor: 'pointer',
                    padding: '0',
                    lineHeight: '1'
                  }}
                >
                  ×
                </button>
              </div>
              <iframe
                src={jusPayIframeUrl}
                title="JusPay Payment"
                style={{
                  width: '100%',
                  height: 'calc(100% - 60px)',
                  border: 'none'
                }}
                allow="payment"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
      </header>
    </div>
  );
};

export default Processing; 