import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Marketplace from './pages/Marketplace';
import PlanDetails from './pages/PlanDetails';
import BundleDetails from './pages/BundleDetails';
import Processing from './pages/Processing';
import PaymentCallback from './pages/PaymentCallback';
import OrderDetails from './pages/OrderDetails';
import reportWebVitals from './reportWebVitals';

// Component to handle domain-based redirection
const DomainHandler = () => {
  useEffect(() => {
    const hostname = window.location.hostname;
    
    if (hostname === 'getfleek.com' || hostname === 'www.getfleek.com') {
      // For getfleek.com, we want to internally use the /fleekusa flow
      // This effect will only run on the client side
      localStorage.setItem('redirectedFromDomain', 'getfleek.com');
    }
  }, []);
  
  return <App />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <DomainHandler />,
  },
  {
    path: "/fleekusa",
    element: <App />,
  },
  {
    path: "/devadvclub",
    element: <App />,
  },
  {
    path: "/skfleek",
    element: <App />,
  },
  {
    path: "/hyperface",
    element: <App />,
  },
  {
    path: "/visa",
    element: <App />,
  },
  {
    path: "/visa1",
    element: <App />,
  },
  {
    path: "/visa2",
    element: <App />,
  },
  {
    path: "/visa3",
    element: <App />,
  },
  {
    path: "/visa4",
    element: <App />,
  },
  {
    path: "/visa5",
    element: <App />,
  },
  {
    path: "/visa6",
    element: <App />,
  },
  {
    path: "/dumPhonePe",
    element: <App />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: "/partnermarketplace",
        element: <Marketplace />,
      },
      {
        path: "/plan-details/:planId",
        element: <PlanDetails />,
      },
      {
        path: "/bundle-details/:bundleId",
        element: <BundleDetails />,
      },
      {
        path: "/processing",
        element: <Processing />,
      },
      {
        path: "/payment-callback",
        element: <PaymentCallback />,
      },
      {
        path: "/order-details",
        element: <OrderDetails />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
