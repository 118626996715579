import React, { useEffect, useState } from 'react';
import logo from '../logo.svg';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';

import { BASE_URL } from '../App';

// Add these styles at the top of the file
const styles = {
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '20px',
    width: '100%',
    maxWidth: '1400px',
    padding: '0 20px',
    boxSizing: 'border-box',
    margin: '0 auto'
  }
};

// Add this CSS to your App.css or create a new CSS file
// .grid-container {
//   display: grid;
//   grid-template-columns: 1fr;
//   gap: 20px;
//   width: 100%;
//   max-width: 1200px;
//   padding: 0 20px;
//   box-sizing: border-box;
//   margin: 0 auto;
// }
// 
// @media (min-width: 768px) {
//   .grid-container {
//     grid-template-columns: repeat(2, 1fr);
//     gap: 30px;
//   }
// }

// Add this helper function at the top of the file
const getCurrencySymbol = (currency) => {
  switch (currency?.toLowerCase()) {
    case 'usd':
      return '$';
    case 'inr':
      return '₹';
    default:
      return '₹'; // Default to INR symbol if currency is not specified
  }
};

const Marketplace = () => {
  const [token, setToken] = useState('');
  const [portalConfig, setPortalConfig] = useState(null);
  const [portalPlans, setPortalPlans] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPortalData = async () => {
      const savedToken = localStorage.getItem('portalToken');
      console.log('Saved token:', savedToken);
      setToken(savedToken);

      if (!savedToken) {
        setError('No token found');
        setIsLoading(false);
        return;
      }

      try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${savedToken}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };

        // Fetch both configuration and plans
        const [configResponse, plansResponse] = await Promise.all([
          fetch(BASE_URL + "/partner/v2/get-portal-configuration", requestOptions),
          fetch(BASE_URL + "/partner/v2/get-portal-plans", requestOptions)
        ]);

        if (!configResponse.ok || !plansResponse.ok) {
          throw new Error(`HTTP error! status: ${configResponse.status} ${plansResponse.status}`);
        }

        const [configResult, plansResult] = await Promise.all([
          configResponse.json(),
          plansResponse.json()
        ]);

        console.log("Portal config:", configResult);
        console.log("Portal plans:", plansResult);
        
        setPortalConfig(configResult);
        // Save config to localStorage
        localStorage.setItem('portalConfig', JSON.stringify(configResult));
        // Ensure we're setting an array of plans
        setPortalPlans(plansResult.plans || []);
      } catch (error) {
        console.error('Detailed error:', error);
        setError(error.message || 'Failed to fetch portal data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPortalData();
  }, []);

  const handlePlanClick = (item) => {
    // Store both the item and its additional details
    const itemWithDetails = {
      ...item,
      plan_activation_message: item.plan_activation_message,
      coupon_type: item.coupon_type,
      steps_to_redeem_coupon: item.steps_to_redeem_coupon
    };
    localStorage.setItem('selectedItem', JSON.stringify(itemWithDetails));
    
    if (item.is_bundle) {
      navigate(`/bundle-details/${item.id}`);
    } else {
      navigate(`/plan-details/${item.id}`);
    }
  };

  if (isLoading) {
    return (
      <div className="App">
        <header className="App-header">
          <Loader />
        </header>
      </div>
    );
  }

  if (error) {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p style={{ color: '#ff6b6b' }}>Error: {error}</p>
        </header>
      </div>
    );
  }

  return (
    <div className="App" style={{ 
      width: '100%',
      maxWidth: '100vw',
      overflowX: 'hidden'
    }}>
      <header className="App-header" style={{
        padding: '20px',
        width: '100%',
        boxSizing: 'border-box'
      }}>
        {/* {portalConfig?.display_logo_url ? (
          <img 
            src={portalConfig.display_logo_url} 
            alt={portalConfig.display_name} 
            style={{ 
              height: '100px',
              width: 'auto',
              marginBottom: '20px',
              marginTop: '60px',
              maxWidth: '100%',
              borderRadius: '10px'
            }}
          />
        ) : (
          <img src={logo} className="App-logo" alt="logo" />
        )} */}
        <div style={{ width: '100%', maxWidth: '100%' }}>
          <h1 style={{ 
            fontSize: '28px',
            margin: '0px 0 30px 0',
            padding: '0 20px',
            textAlign: 'center'
          }}>{portalConfig?.display_name || 'Marketplace'}</h1>
          
          {Array.isArray(portalPlans) && portalPlans.length > 0 && (
            <>
              {/* Individual Plans Section */}
              {portalPlans.some(plan => !plan.is_bundle) && (
                <div style={{ width: '100%', maxWidth: '100%' }}>
                  <h3 style={{ 
                    fontSize: '24px', 
                    marginBottom: '30px',
                    textAlign: 'center',
                    padding: '0 20px'
                  }}>Available Plans</h3>
                  <div className="grid-container">
                    {portalPlans
                      .filter(plan => !plan.is_bundle)
                      .map((plan, index) => (
                        <div 
                          key={index} 
                          className="plan-card"
                          onClick={() => handlePlanClick(plan)}
                          style={{
                            backgroundColor: '#1a1d24',
                            borderRadius: '22px',
                            padding: '30px 20px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            boxShadow: '6px 6px 6px rgba(0, 62, 36, 0.24)',
                            width: '100%',
                            boxSizing: 'border-box',
                            minHeight: '420px',
                            gap: '20px',
                            position: 'relative'
                          }}
                        >
                          {/* Discount Tag */}
                          {plan.plan_mrp && plan.plan_mrp > plan.plan_cost && (
                            <div style={{
                              position: 'absolute',
                              top: '20px',
                              right: '20px',
                              backgroundColor: '#B4833E',
                              color: '#ffffff',
                              padding: '6px 12px',
                              borderRadius: '6px',
                              fontSize: '14px',
                              fontWeight: '600',
                              boxShadow: '0 2px 4px rgba(180, 131, 62, 0.2)',
                              zIndex: 1,
                              animation: 'glowPulse 2s infinite'
                            }}>
                              {Math.round(((plan.plan_mrp - plan.plan_cost) / plan.plan_mrp) * 100)}% OFF
                            </div>
                          )}

                          {plan.vendor_logo_url && (
                            <div style={{
                              width: '100%',
                              marginBottom: '25px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              minHeight: '80px',
                              position: 'relative'
                            }}>
                              <img 
                                src={plan.vendor_logo_url} 
                                alt={plan.vendor_name}
                                style={{ 
                                  width: '100%',
                                  maxWidth: '180px',
                                  height: 'auto',
                                  objectFit: 'contain',
                                  transition: 'transform 0.3s ease',
                                  filter: 'drop-shadow(0 0 0 rgba(180, 131, 62, 0))',
                                  ':hover': {
                                    transform: 'translateY(-2px)',
                                    filter: 'drop-shadow(0 4px 12px rgba(180, 131, 62, 0.3))'
                                  }
                                }}
                              />
                            </div>
                          )}
                          <h4 style={{ 
                            fontSize: '22px',
                            margin: '0 0 20px 0',
                            color: '#ffffff'
                          }}>{plan.vendor_name}</h4>
                          <p style={{
                            fontSize: '15px',
                            lineHeight: '1.6',
                            margin: '0 0 25px 0',
                            color: '#cccccc',
                            flex: 1
                          }}>{plan.plan_description}</p>
                          <div style={{
                            marginTop: 'auto',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '10px'
                          }}>
                            <p style={{
                              fontSize: '14px',
                              color: '#888888',
                              margin: '0'
                            }}>{plan.plan_name}</p>
                            
                            {/* Price and Discount Section */}
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              gap: '5px',
                              marginBottom: '10px'
                            }}>
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                              }}>
                                {plan.plan_mrp && plan.plan_mrp > plan.plan_cost && (
                                  <span style={{
                                    color: '#888888',
                                    textDecoration: 'line-through',
                                    fontSize: '14px'
                                  }}>
                                    {getCurrencySymbol(plan.currency)}{plan.plan_mrp}
                                  </span>
                                )}
                                <span style={{
                                  color: '#ffffff',
                                  fontSize: '24px',
                                  fontWeight: 'bold'
                                }}>
                                  {getCurrencySymbol(plan.currency)}{plan.plan_cost}
                                </span>
                              </div>
                            </div>

                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handlePlanClick(plan);
                              }}
                              className="buy-button"
                            >
                              <span>Buy Now</span>
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {/* Bundles Section */}
              {portalPlans.some(plan => plan.is_bundle) && (
                <div style={{ width: '100%', maxWidth: '100%' }}>
                  <h3 style={{ 
                    fontSize: '24px', 
                    marginBottom: '30px',
                    textAlign: 'center',
                    padding: '0 20px'
                  }}>Available Bundles</h3>
                  {portalPlans
                    .filter(plan => plan.is_bundle)
                    .map((bundle, index) => (
                      <div 
                        key={index} 
                        className="bundle-card"
                        onClick={() => handlePlanClick(bundle)}
                        style={{
                          backgroundColor: '#1a1d24',
                          border: '1px solid rgba(97, 218, 251, 0.2)',
                          position: 'relative',
                          overflow: 'hidden',
                          '@media (max-width: 768px)': {
                            padding: '40px 20px',
                            minHeight: '500px'
                          }
                        }}
                      >
                        {/* Bundle Info Column */}
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          textAlign: 'center',
                          padding: '30px 20px',
                          backgroundColor: 'rgba(97, 218, 251, 0.05)',
                          borderRadius: '8px',
                          gap: '25px'
                        }}>
                          {bundle.product_logo_url && (
                            <img 
                              src={bundle.product_logo_url} 
                              alt={bundle.product_name}
                              style={{ 
                                width: '100%',
                                maxWidth: '200px',
                                height: 'auto',
                                marginBottom: '20px'
                              }}
                            />
                          )}
                          <h4 style={{ 
                            fontSize: '24px',
                            margin: '0 0 15px 0',
                            color: '#ffffff'
                          }}>{bundle.product_name}</h4>
                          <p style={{
                            fontSize: '16px',
                            lineHeight: '1.5',
                            margin: '0 0 20px 0',
                            color: '#cccccc'
                          }}>{bundle.plan_description}</p>
                          <div style={{
                            marginTop: 'auto',
                            borderTop: '1px solid rgba(97, 218, 251, 0.2)',
                            paddingTop: '20px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '15px'
                          }}>
                            <p style={{
                              fontSize: '32px',
                              fontWeight: 'bold',
                              margin: '0',
                              color: '#00ac9d'
                            }}>{getCurrencySymbol(bundle.currency)}{bundle.bundle_cost}</p>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handlePlanClick(bundle);
                              }}
                              className="buy-button"
                            >
                              <span>Buy {getCurrencySymbol(bundle.currency)}{bundle.bundle_cost}</span>
                            </button>
                          </div>
                        </div>

                        {/* Included Plans Column */}
                        {bundle.plan_list && bundle.plan_list.length > 0 && (
                          <div className="included-plans-grid" style={{
                            padding: '20px',
                            backgroundColor: 'rgba(255, 255, 255, 0.02)' // Very subtle highlight
                          }}>
                            {bundle.plan_list.map((plan, planIndex) => (
                              <div key={planIndex} style={{
                                backgroundColor: 'rgba(255,255,255,0.05)',
                                borderRadius: '8px',
                                padding: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '15px',
                                border: '1px solid rgba(255,255,255,0.1)',
                                transition: 'transform 0.2s ease',
                                cursor: 'pointer',
                                ':hover': {
                                  transform: 'translateY(-2px)',
                                  backgroundColor: 'rgba(255,255,255,0.07)'
                                }
                              }}>
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '15px'
                                }}>
                                  {plan.vendor_logo_url && (
                                    <img 
                                      src={plan.vendor_logo_url} 
                                      alt={plan.vendor_name}
                                      style={{ 
                                        width: 'auto',
                                        height: '40px'
                                      }}
                                    />
                                  )}
                                  <h6 style={{
                                    fontSize: '18px',
                                    margin: '0',
                                    color: '#ffffff'
                                  }}>{plan.vendor_name}</h6>
                                </div>
                                <p style={{
                                  fontSize: '14px',
                                  margin: '0',
                                  color: '#cccccc',
                                  lineHeight: '1.4'
                                }}>{plan.plan_description}</p>
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginTop: 'auto',
                                  paddingTop: '15px',
                                  borderTop: '1px solid rgba(255,255,255,0.1)'
                                }}>
                                  <span style={{ 
                                    color: '#888888',
                                    fontSize: '14px'
                                  }}>{plan.plan_name}</span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </>
          )}

          <div style={{ 
            marginTop: '20px', 
            fontSize: '12px', 
            opacity: 0.7,
            padding: '0 20px',
            width: '100%',
            boxSizing: 'border-box'
          }}>
            {/* <p>Session Token:</p>
            <pre style={{ 
              backgroundColor: '#282c34', 
              padding: '10px', 
              borderRadius: '4px',
              maxWidth: '300px',
              overflow: 'auto'
            }}>
              {token}
            </pre> */}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Marketplace; 