import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../App.css';

const BundleDetails = () => {
  const { bundleId } = useParams();
  const navigate = useNavigate();
  const selectedBundle = JSON.parse(localStorage.getItem('selectedItem'));

  if (!selectedBundle) {
    return (
      <div className="App">
        <header className="App-header">
          <p>No bundle details found</p>
          <button
            onClick={() => navigate('/partnermarketplace')}
            style={{
              backgroundColor: '#61dafb',
              color: '#1a1d24',
              border: 'none',
              borderRadius: '8px',
              padding: '12px 24px',
              fontSize: '16px',
              cursor: 'pointer',
              marginTop: '20px'
            }}
          >
            Back to Marketplace
          </button>
        </header>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <div style={{
          backgroundColor: '#1a1d24',
          borderRadius: '12px',
          padding: '30px',
          maxWidth: '1000px',
          width: '90%',
          margin: '20px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}>
          {/* Back Button */}
          <button
            onClick={() => navigate('/partnermarketplace')}
            style={{
              backgroundColor: 'transparent',
              border: '1px solid #61dafb',
              color: '#61dafb',
              borderRadius: '8px',
              padding: '8px 16px',
              cursor: 'pointer',
              marginBottom: '30px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            ← Back to Marketplace
          </button>

          {/* Bundle Header */}
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px',
            marginBottom: '30px'
          }}>
            {selectedBundle.product_logo_url && (
              <img 
                src={selectedBundle.product_logo_url}
                alt={selectedBundle.product_name}
                style={{
                  width: 'auto',
                  height: '80px'
                }}
              />
            )}
            <h1 style={{ 
              fontSize: '32px',
              margin: '0'
            }}>{selectedBundle.product_name}</h1>
          </div>

          {/* Bundle Description */}
          <p style={{
            fontSize: '18px',
            lineHeight: '1.6',
            color: '#cccccc',
            margin: '0 0 30px 0'
          }}>{selectedBundle.plan_description}</p>

          {/* Bundle Price */}
          <div style={{
            backgroundColor: 'rgba(97, 218, 251, 0.1)',
            borderRadius: '8px',
            padding: '20px',
            marginBottom: '30px',
            textAlign: 'center'
          }}>
            <p style={{ 
              fontSize: '16px',
              color: '#888888',
              margin: '0 0 5px 0'
            }}>Bundle Price</p>
            <p style={{
              fontSize: '36px',
              fontWeight: 'bold',
              margin: '0',
              color: '#61dafb'
            }}>₹{selectedBundle.bundle_cost}</p>
          </div>

          {/* Included Plans */}
          {selectedBundle.plan_list && selectedBundle.plan_list.length > 0 && (
            <div>
              <h3 style={{
                fontSize: '24px',
                marginBottom: '20px'
              }}>Included Plans</h3>
              <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: '20px',
                '@media (min-width: 768px)': {
                  gridTemplateColumns: 'repeat(2, 1fr)'
                }
              }}>
                {selectedBundle.plan_list.map((plan, index) => (
                  <div key={index} style={{
                    backgroundColor: 'rgba(255,255,255,0.05)',
                    borderRadius: '8px',
                    padding: '20px'
                  }}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '15px',
                      marginBottom: '15px'
                    }}>
                      {plan.vendor_logo_url && (
                        <img 
                          src={plan.vendor_logo_url}
                          alt={plan.vendor_name}
                          style={{ 
                            width: 'auto',
                            height: '40px'
                          }}
                        />
                      )}
                      <h4 style={{
                        fontSize: '20px',
                        margin: '0'
                      }}>{plan.vendor_name}</h4>
                    </div>
                    <p style={{
                      fontSize: '14px',
                      color: '#cccccc',
                      margin: '0 0 15px 0'
                    }}>{plan.plan_description}</p>
                    <p style={{
                      fontSize: '14px',
                      color: '#888888',
                      margin: '0'
                    }}>{plan.plan_name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Purchase Button */}
          <button
            style={{
              backgroundColor: '#61dafb',
              color: '#1a1d24',
              border: 'none',
              borderRadius: '8px',
              padding: '16px 32px',
              fontSize: '20px',
              fontWeight: 'bold',
              cursor: 'pointer',
              width: '100%',
              marginTop: '30px'
            }}
          >
            Purchase Bundle
          </button>
        </div>
      </header>
    </div>
  );
};

export default BundleDetails; 