import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import '../App.css';
import { BASE_URL } from '../App';
import Loader from '../components/Loader';

const PaymentCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState('PENDING');
  const [error, setError] = useState(null);
  const orderId = searchParams.get('order_id');

  const checkPaymentStatus = async () => {
    try {
      const savedToken = localStorage.getItem('portalToken');
      const response = await fetch(
        `${BASE_URL}/partner/v2/get-portal-payment-status?order_id=${orderId}`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${savedToken}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch payment status');
      }

      const data = await response.json();
      console.log('Payment status response:', data);
      
      if (data.order_status === 'SUCCESS') {
        setStatus('SUCCESS');
        // Navigate to order details page
        setTimeout(() => {
          navigate('/order-details', { 
            state: { 
              orderId,
              paymentDetails: data.payment_attempts[0]
            }
          });
        }, 2000); // Wait 2 seconds before redirecting
      } else if (data.order_status === 'FAILED') {
        setStatus('FAILED');
        setError('Payment failed. Please try again.');
      } else {
        // Continue polling if status is still pending
        setTimeout(checkPaymentStatus, 2000); // Poll every 2 seconds
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
      setError(error.message);
      setStatus('FAILED');
    }
  };

  useEffect(() => {
    if (!orderId) {
      setError('No order ID found');
      return;
    }

    checkPaymentStatus();

    // Cleanup timeout on unmount
    return () => clearTimeout();
  }, [orderId]);

  return (
    <div className="App">
      <header className="App-header">
        <div style={{
          backgroundColor: '#1a1d24',
          borderRadius: '12px',
          padding: '30px',
          maxWidth: '800px',
          width: '90%',
          margin: '20px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}>
          <h2 style={{ marginBottom: '20px' }}>Verifying Payment</h2>
          
          {error ? (
            <div style={{
              backgroundColor: 'rgba(255, 87, 87, 0.1)',
              padding: '20px',
              borderRadius: '8px',
              marginBottom: '20px'
            }}>
              <p style={{ 
                color: '#ff5757',
                margin: '0 0 20px 0' 
              }}>{error}</p>
              <button
                onClick={() => navigate('/partnermarketplace')}
                style={{
                  backgroundColor: '#61dafb',
                  color: '#1a1d24',
                  border: 'none',
                  borderRadius: '8px',
                  padding: '12px 24px',
                  fontSize: '16px',
                  cursor: 'pointer'
                }}
              >
                Back to Marketplace
              </button>
            </div>
          ) : (
            <div style={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              padding: '20px',
              borderRadius: '8px'
            }}>
              <Loader />
              <p style={{ marginTop: '20px' }}>
                {status === 'SUCCESS' 
                  ? 'Payment successful! Redirecting...'
                  : 'Verifying payment status...'}
              </p>
              <p style={{ 
                fontSize: '12px',
                color: '#888',
                marginTop: '10px' 
              }}>
                Order ID: {orderId}
              </p>
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default PaymentCallback; 