import React from 'react';
import Lottie from 'lottie-react';
import loaderAnimation from '../loaders.json';

const Loader = () => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%'
    }}>
      <Lottie 
        animationData={loaderAnimation}
        loop={true}
        style={{
          width: 200,
          height: 200
        }}
      />
    </div>
  );
};

export default Loader; 