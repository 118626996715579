import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../App.css';
import Loader from '../components/Loader';
import { BASE_URL } from '../App';

const getCurrencySymbol = (currency) => {
  switch (currency?.toLowerCase()) {
    case 'usd':
      return '$';
    case 'inr':
      return '₹';
    default:
      return '₹'; // Default to INR symbol if currency is not specified
  }
};

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const PlanDetails = () => {
  const { planId } = useParams();
  const navigate = useNavigate();
  const selectedPlan = JSON.parse(localStorage.getItem('selectedItem'));
  const portalConfig = JSON.parse(localStorage.getItem('portalConfig'));
  
  // Check if the partner is Hyperface
  const isHyperface = portalConfig?.display_name?.toLowerCase() === 'hyperface';
  
  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [showCouponInput, setShowCouponInput] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [isApplying, setIsApplying] = useState(false);
  const [couponError, setCouponError] = useState('');
  const [couponSuccess, setCouponSuccess] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(0);

  if (!selectedPlan) {
    return (
      <div className="App">
        <header className="App-header">
          <p>No plan details found</p>
          <button
            onClick={() => navigate('/partnermarketplace')}
            style={{
              backgroundColor: '#00ac9d',
              color: '#1a1d24',
              border: 'none',
              borderRadius: '8px',
              padding: '12px 24px',
              fontSize: '16px',
              cursor: 'pointer',
              marginTop: '20px'
            }}
          >
            Back to Marketplace
          </button>
        </header>
      </div>
    );
  }

  const handlePurchase = async () => {
    // Skip email validation for Hyperface partner
    if (!isHyperface) {
      if (!email || !email.trim()) {
        setEmailError('Please enter your email to continue');
        document.querySelector('.contact-details').scrollIntoView({ behavior: 'smooth' });
        return;
      }

      if (!isValidEmail(email)) {
        setEmailError('Please enter a valid email address');
        document.querySelector('.contact-details').scrollIntoView({ behavior: 'smooth' });
        return;
      }
    }
    
    setEmailError(false);
    setIsProcessing(true);
    const savedToken = localStorage.getItem('portalToken');

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${savedToken}`);
    myHeaders.append("Content-Type", "application/json");

    // Create request body based on partner
    const requestBody = {
      "plan_id": selectedPlan.plan_id,
      "user_details": {
        "email": isHyperface ? "" : email
      },
      "unique_request_id": `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`
    };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(requestBody),
      redirect: "follow"
    };

    try {
      const response = await fetch(BASE_URL + "/partner/v2/create-portal-order", requestOptions);
      const result = await response.json();
      navigate('/processing', { state: { orderResponse: result } });
    } catch (error) {
      console.error(error);
      setIsProcessing(false);
    }
  };

  const handleApplyCoupon = async () => {
    setIsApplying(true);
    setCouponError('');
    setCouponSuccess(false);
    
    // Start loading animation
    setLoadingProgress(0);
    const animationInterval = setInterval(() => {
      setLoadingProgress(prev => Math.min(prev + 2, 90));
    }, 10);

    const savedToken = localStorage.getItem('portalToken');

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${savedToken}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "code": couponCode,
      "plan_id": selectedPlan.plan_id
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch("https://api-dev.getfleek.app/partner/v2/validate-portal-discount", requestOptions);
      const result = await response.json();

      if (response.ok) {
        setLoadingProgress(100); // Complete the loading
        setCouponSuccess(true);
        setDiscountedPrice(result.discounted_price);
      } else {
        setLoadingProgress(0);
        setCouponError(result.message || 'Invalid coupon code');
      }
    } catch (error) {
      setLoadingProgress(0);
      console.error('Error:', error);
      setCouponError('Failed to validate coupon');
    } finally {
      clearInterval(animationInterval);
      setIsApplying(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div style={{
          backgroundColor: '#1a1d24',
          borderRadius: '12px',
          padding: '30px',
          maxWidth: '800px',
          width: '90%',
          margin: '20px',
          position: 'relative'
        }}>
        

          {/* Back Button */}
          <button
            onClick={() => navigate('/partnermarketplace')}
            style={{
              backgroundColor: 'transparent',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              color: '#ffffff',
              borderRadius: '8px',
              padding: '8px 16px',
              cursor: 'pointer',
              marginBottom: '30px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            ← Back to Marketplace
          </button>

          {/* Plan Details */}
          <div style={{ 
            marginBottom: '30px',
            position: 'relative',
            padding: '30px',
            borderRadius: '12px',
            overflow: 'hidden'
          }}>
            {/* Background Image with Gradient */}
            {selectedPlan.vendor_logo_url && (
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `
                  linear-gradient(
                    to bottom,
                    rgba(26, 29, 36, 0.74) 0%,
                    rgb(26, 29, 36) 100%
                  ),
                  url(${selectedPlan.vendor_logo_url})
                `,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                opacity: 0.3,
                zIndex: 0
              }} />
            )}

            {/* Content */}
            <div style={{ position: 'relative', zIndex: 1 }}>
              <div style={{
                position: 'relative',
                width: '100%',
                marginBottom: '25px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '80px'
              }}>
                {selectedPlan.plan_mrp && selectedPlan.plan_mrp > selectedPlan.plan_cost && (
                  <div style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    backgroundColor: '#B4833E',
                    color: '#ffffff',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    fontSize: '16px',
                    fontWeight: '600',
                    boxShadow: '0 2px 4px rgba(180, 131, 62, 0.2)',
                    zIndex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px'
                  }}>
                    <span style={{ fontSize: '18px' }}>🏷️</span>
                    {Math.round(((selectedPlan.plan_mrp - selectedPlan.plan_cost) / selectedPlan.plan_mrp) * 100)}% OFF
                  </div>
                )}
                
                {selectedPlan.vendor_logo_url && (
                  <img 
                    src={selectedPlan.vendor_logo_url}
                    alt={selectedPlan.vendor_name}
                    style={{ 
                      width: '100%',
                      maxWidth: '200px',
                      height: 'auto',
                      objectFit: 'contain'
                    }}
                  />
                )}
              </div>
              <h1 style={{ 
                fontSize: '32px',
                margin: '0 0 20px 0',
                color: '#ffffff'
              }}>{selectedPlan.vendor_name}</h1>
              <p style={{
                fontSize: '18px',
                lineHeight: '1.6',
                color: '#cccccc',
                margin: '0'
              }}>{selectedPlan.plan_description}</p>
            </div>
          </div>

          {/* Price Info */}
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
            backgroundColor: 'rgba(0, 172, 157, 0.05)',
            borderRadius: '8px',
            marginBottom: '30px'
          }}>
            <div>
              <p style={{
                fontSize: '16px',
                color: '#888888',
                margin: '0 0 5px 0',
                textAlign: 'left'
              }}>Duration</p>
              <p style={{
                fontSize: '20px',
                margin: '0',
                color: '#ffffff'
              }}>{selectedPlan.plan_name}</p>
            </div>
            <div style={{ textAlign: 'right' }}>
              <p style={{
                fontSize: '16px',
                color: '#888888',
                margin: '0 0 5px 0'
              }}>Price</p>
              <p style={{
                fontSize: '28px',
                fontWeight: 'bold',
                margin: '0',
                color: '#00ac9d'
              }}>{getCurrencySymbol(selectedPlan.currency)}{selectedPlan.plan_cost}</p>
            </div>
          </div>

          {/* Price Details */}
          <div style={{
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '8px',
            padding: '20px',
            marginBottom: '20px'
          }}>
            <h3 style={{
              margin: '0 0 15px 0',
              fontSize: '18px',
              color: '#cccccc'
            }}>Price Details</h3>

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px'
            }}>
              {selectedPlan.plan_mrp && selectedPlan.plan_mrp > selectedPlan.plan_cost && (
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                  <span style={{ color: '#888888' }}>MRP</span>
                  <span style={{
                    color: '#888888',
                    textDecoration: 'line-through'
                  }}>
                    {getCurrencySymbol(selectedPlan.currency)}{selectedPlan.plan_mrp}
                  </span>
                </div>
              )}
              
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <span style={{ color: '#888888' }}>Price</span>
                <span style={{
                  color: '#ffffff',
                  fontSize: '24px',
                  fontWeight: 'bold'
                }}>
                  {getCurrencySymbol(selectedPlan.currency)}{selectedPlan.plan_cost}
                </span>
              </div>

              {selectedPlan.plan_mrp && selectedPlan.plan_mrp > selectedPlan.plan_cost && (
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingTop: '10px',
                  borderTop: '1px solid rgba(255, 255, 255, 0.1)'
                }}>
                  <span style={{ color: '#888888' }}>Discount</span>
                  <span style={{
                    color: '#B4833E',
                    fontWeight: '500'
                  }}>
                    {Math.round(((selectedPlan.plan_mrp - selectedPlan.plan_cost) / selectedPlan.plan_mrp) * 100)}% OFF
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Coupon Section */}
          <div style={{
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            borderRadius: '8px',
            padding: '20px',
            marginBottom: '20px'
          }}>
            <div 
              onClick={() => setShowCouponInput(!showCouponInput)}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer'
              }}
            >
              <h3 style={{
                margin: '0',
                fontSize: '18px',
                color: '#cccccc',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}>
                <span style={{ fontSize: '20px' }}>🎫</span>
                Apply Coupon
              </h3>
              <span style={{ 
                fontSize: '20px',
                transition: 'transform 0.3s ease',
                transform: showCouponInput ? 'rotate(180deg)' : 'rotate(0deg)'
              }}>
                ▼
              </span>
            </div>

            {showCouponInput && (
              <div style={{
                marginTop: '15px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
                <input
                  type="text"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  placeholder="Enter coupon code"
                  style={{
                    width: '100%',
                    padding: '12px',
                    borderRadius: '6px',
                    border: '1px solid rgba(255, 255, 255, 0.1)',
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    color: '#ffffff',
                    fontSize: '16px',
                    boxSizing: 'border-box'
                  }}
                />
                <button
                  onClick={handleApplyCoupon}
                  disabled={!couponCode.trim() || isApplying}
                  style={{
                    backgroundColor: couponSuccess ? '#4CAF50' : '#00ac9d',
                    color: '#1a1d24',
                    border: 'none',
                    borderRadius: '6px',
                    padding: '12px',
                    fontSize: '16px',
                    fontWeight: '500',
                    cursor: couponCode.trim() && !isApplying ? 'pointer' : 'default',
                    opacity: couponCode.trim() && !isApplying ? 1 : 0.7,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    position: 'relative',
                    overflow: 'hidden'
                  }}
                >
                  {/* Loading bar */}
                  {isApplying && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: `${loadingProgress}%`,
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        transition: 'width 0.1s linear'
                      }}
                    />
                  )}
                  
                  {/* Button content */}
                  <span style={{ position: 'relative', zIndex: 1 }}>
                    {isApplying ? 'Applying...' : 
                     couponSuccess ? (
                       <>
                         <span style={{ fontSize: '16px' }}>✓</span>
                         <span>Coupon Applied</span>
                       </>
                     ) : 'Apply Coupon'}
                  </span>
                </button>
                {couponError && (
                  <p style={{
                    color: '#ff5757',
                    fontSize: '14px',
                    margin: '8px 0 0 0',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px'
                  }}>
                    <span style={{ fontSize: '16px' }}>⚠️</span>
                    {couponError}
                  </p>
                )}
                {couponSuccess && discountedPrice !== null && (
                  <p style={{
                    color: '#4CAF50',
                    fontSize: '14px',
                    margin: '8px 0 0 0',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px'
                  }}>
                    <span style={{ fontSize: '16px' }}>💰</span>
                    New price: {getCurrencySymbol(selectedPlan.currency)}{discountedPrice}
                  </p>
                )}
              </div>
            )}
          </div>

          {/* Contact Details Section - Only show if not Hyperface */}
          {!isHyperface && (
            <div 
              className="contact-details"
              style={{
                backgroundColor: emailError ? 'rgba(255, 87, 87, 0.1)' : 'rgba(255, 255, 255, 0.05)',
                borderRadius: '8px',
                padding: '20px',
                marginBottom: '30px',
                border: emailError ? '1px solid rgba(255, 87, 87, 0.3)' : '1px solid rgba(255, 255, 255, 0.1)',
                transition: 'all 0.3s ease'
              }}>
              <h3 style={{ 
                margin: '0 0 20px 0',
                fontSize: '20px',
                color: emailError ? '#ff5757' : '#ffffff',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}>
                {emailError && <span style={{ fontSize: '20px' }}>⚠️</span>}
                Contact Details
              </h3>

              <div style={{ marginBottom: '20px' }}>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (emailError) setEmailError(false);
                  }}
                  style={{
                    width: '100%',
                    padding: '12px',
                    borderRadius: '6px',
                    border: emailError ? '1px solid #ff5757' : '1px solid rgba(255, 255, 255, 0.1)',
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    color: '#ffffff',
                    fontSize: '16px',
                    boxSizing: 'border-box',
                    outline: 'none',
                    transition: 'all 0.3s ease'
                  }}
                  placeholder="Enter your email"
                />
                {emailError && (
                  <p style={{
                    color: '#ff5757',
                    fontSize: '14px',
                    margin: '8px 0 0 0',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px'
                  }}>
                    {emailError}
                  </p>
                )}
              </div>
            </div>
          )}

          {/* Purchase Button */}
          <button
            onClick={handlePurchase}
            disabled={isProcessing || (!isHyperface && !email)}
            style={{
              backgroundColor: isProcessing ? '#008f83' : '#00ac9d',
              color: '#1a1d24',
              border: 'none',
              borderRadius: '8px',
              padding: '16px 32px',
              fontSize: '20px',
              fontWeight: 'bold',
              cursor: isProcessing ? 'default' : 'pointer',
              width: '100%',
              opacity: isProcessing ? 0.7 : 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px'
            }}
          >
            {isProcessing ? (
              <>
                <Loader />
                <span>Processing...</span>
              </>
            ) : (
              `Purchase ${getCurrencySymbol(selectedPlan.currency)}${selectedPlan.plan_cost}`
            )}
          </button>

          {/* Plan ID */}
          <div style={{
            textAlign: 'center',
            color: '#666666',
            fontSize: '12px',
            marginTop: '20px'
          }}>
            Plan ID: {selectedPlan.plan_id}
          </div>
        </div>
      </header>
    </div>
  );
};

export default PlanDetails; 