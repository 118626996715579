import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../App.css';
import { BASE_URL } from '../App';
import Loader from '../components/Loader';

const CopyableCode = ({ code }) => {
  // Initialize copied state from localStorage
  const [copied, setCopied] = useState(() => {
    const savedState = localStorage.getItem(`copied_${code}`);
    return savedState === 'true';
  });

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopied(true);
      // Save copied state to localStorage
      localStorage.setItem(`copied_${code}`, 'true');
    } catch (err) {
      console.error('Failed to copy code:', err);
    }
  };

  return (
    <div style={{
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '8px',
      padding: '20px',
      marginTop: '20px',
      position: 'relative'
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        padding: '15px',
        borderRadius: '6px',
        border: '1px dashed rgba(0, 0, 0, 0.2)'
      }}>
        <code style={{
          color: '#000000',
          fontSize: '20px',
          fontWeight: 'bold',
          flex: 1
        }}>{code}</code>
        <button
          onClick={handleCopy}
          style={{
            backgroundColor: copied ? '#fff7' : '#fff6',
            color: '#000000',
            border: 'none',
            borderRadius: '6px',
            padding: '8px 16px',
            cursor: 'pointer',
            fontSize: '14px',
            fontWeight: '500',
            transition: 'all 0.3s ease',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            alignItems: 'center',
            gap: '6px'
          }}
        >
          <span style={{ fontSize: '16px' }}>📋</span>
          {copied ? 'Copied!' : 'Copy'}
        </button>
      </div>
    </div>
  );
};

const OrderDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { orderId, paymentDetails } = location.state || {};
  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log('OrderDetails component rendered', {
    orderId,
    paymentDetails,
    isLoading,
    hasError: !!error,
    timestamp: new Date().toISOString()
  });

  useEffect(() => {
    const fetchOrderDetails = async () => {
      console.log('Fetching order details for order:', orderId);
      
      try {
        const savedToken = localStorage.getItem('portalToken');
        console.log('Making API request to get-order-details');
        
        const response = await fetch(
          `${BASE_URL}/partner/v2/get-order-details/${orderId}`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${savedToken}`
            }
          }
        );

        if (!response.ok) {
          console.error('API request failed:', {
            status: response.status,
            statusText: response.statusText
          });
          throw new Error('Failed to fetch order details');
        }

        const data = await response.json();
        console.log('Order details API response:', data);
        
        if (!data.order_id) {
          console.error('Invalid order details received:', data);
          throw new Error('Invalid order details received');
        }

        // Get the selected plan details from localStorage
        const selectedPlan = JSON.parse(localStorage.getItem('selectedItem'));
        
        // Merge order details with plan details
        const mergedDetails = {
          ...data,
          plan_activation_message: selectedPlan?.plan_activation_message || data.plan_activation_message,
          coupon_type: selectedPlan?.coupon_type || data.coupon_type,
          steps_to_redeem_coupon: selectedPlan?.steps_to_redeem_coupon || data.steps_to_redeem_coupon
        };

        setOrderDetails(mergedDetails);
        console.log('Order details set successfully');
      } catch (error) {
        console.error('Error in fetchOrderDetails:', {
          error,
          message: error.message,
          stack: error.stack
        });
        setError(error.message);
      }
      setIsLoading(false);
    };

    if (orderId) {
      console.log('OrderId available, initiating fetch');
      fetchOrderDetails();
    } else {
      console.log('No orderId available, setting error state');
      setError('No order ID found');
      setIsLoading(false);
    }

    return () => {
      console.log('OrderDetails component cleanup');
    };
  }, [orderId]);

  // Log state changes
  useEffect(() => {
    console.log('Order details state updated:', {
      hasOrderDetails: !!orderDetails,
      isLoading,
      error,
      timestamp: new Date().toISOString()
    });
  }, [orderDetails, isLoading, error]);

  useEffect(() => {
    console.log('Current order details:', orderDetails);
  }, [orderDetails]);

  if (isLoading) {
    return (
      <div className="App">
        <header className="App-header">
          <Loader />
        </header>
      </div>
    );
  }

  if (error || !orderDetails) {
    return (
      <div className="App">
        <header className="App-header">
          <div style={{
            backgroundColor: '#1a1d24',
            borderRadius: '12px',
            padding: '30px',
            maxWidth: '800px',
            width: '90%',
            margin: '20px',
            textAlign: 'center'
          }}>
            <p style={{ marginBottom: '20px' }}>{error || 'No order details found'}</p>
            {/* <button
              onClick={() => navigate('/partnermarketplace')}
              style={{
                backgroundColor: '#61dafb',
                color: '#1a1d24',
                border: 'none',
                borderRadius: '8px',
                padding: '12px 24px',
                fontSize: '16px',
                cursor: 'pointer'
              }}
            >
              Back to Marketplace
            </button> */}
          </div>
        </header>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <div style={{
          backgroundColor: '#1a1d24',
          borderRadius: '12px',
          padding: '30px',
          maxWidth: '800px',
          width: '90%',
          margin: '20px'
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
            marginBottom: '30px',
            marginTop: '30px'
          }}>
            <div style={{
              backgroundColor: '#4CAF50',
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '20px'
            }}>
              ✓
            </div>
            <div>
              <h2 style={{ 
                margin: '0',
                fontSize: '24px'
              }}>Order Confirmed</h2>
              <p style={{
                margin: '5px 0 0 0',
                color: '#888',
                fontSize: '14px'
              }}>
                Order ID: {orderDetails.order_id}
              </p>
            </div>
          </div>

          <div style={{
            backgroundColor: 'rgba(255, 255, 255, 0.03)',
            borderRadius: '8px',
            padding: '20px',
            marginBottom: '20px'
          }}>
            <h3 style={{
              margin: '0 0 15px 0',
              fontSize: '18px',
              color: '#cccccc'
            }}>Order Details</h3>
            
            <div style={{
              display: 'grid',
              gap: '10px'
            }}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '14px'
              }}>
                <span style={{ color: '#888' }}>Partner</span>
                <span style={{ color: '#ffffff' }}>{orderDetails.partner}</span>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '14px'
              }}>
                <span style={{ color: '#888' }}>Plan</span>
                <span style={{ color: '#ffffff' }}>{orderDetails.partner_plan}</span>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '14px'
              }}>
                <span style={{ color: '#888' }}>Amount</span>
                <span style={{ color: '#ffffff' }}>
                  {orderDetails.currency.toUpperCase()} {orderDetails.order_amount}
                </span>
              </div>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '14px'
              }}>
                <span style={{ color: '#888' }}>Date</span>
                <span style={{ color: '#ffffff' }}>
                  {new Date(orderDetails.created_at).toLocaleString()}
                </span>
              </div>
              {orderDetails.user_details?.email && (
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '14px'
                }}>
                  <span style={{ color: '#888' }}>Email</span>
                  <span style={{ color: '#ffffff' }}>{orderDetails.user_details.email}</span>
                </div>
              )}
            </div>
          </div>

          {orderDetails.coupon_code && (
            <div className="coupon-container">
              <div className="coupon-content">
                <div className="coupon-header">
                  <span style={{ fontSize: '24px' }}>🎫</span>
                  <h3 className="coupon-title">Your Coupon Code</h3>
                </div>
                <CopyableCode code={orderDetails.coupon_code} />
              </div>
            </div>
          )}

          {orderDetails.plan_activation_message && (
            <button
              onClick={() => window.open(orderDetails.activation_url, '_blank')}
              className="activate-button"
              style={{
                backgroundColor: '#00ac9d',
                color: '#1a1d24',
                border: 'none',
                borderRadius: '8px',
                padding: '16px 32px',
                fontSize: '18px',
                fontWeight: 'bold',
                cursor: 'pointer',
                width: '100%',
                marginBottom: '20px',
                transition: 'all 0.3s ease',
                position: 'relative',
                overflow: 'hidden'
              }}
            >
              Activate Now
            </button>
          )}

          {orderDetails.steps_to_redeem_coupon && (
            <div style={{
              backgroundColor: 'rgba(0, 172, 157, 0.05)',
              borderRadius: '8px',
              padding: '20px',
              marginBottom: '20px'
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '15px'
              }}>
                <span style={{ fontSize: '24px' }}>🎟️</span>
                <h3 style={{ 
                  margin: '0',
                  fontSize: '20px',
                  color: '#00ac9d'
                }}>Steps to Redeem</h3>
              </div>
              
              <div style={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                padding: '15px',
                borderRadius: '6px',
                border: '1px solid rgba(0, 172, 157, 0.1)'
              }}>
                <p style={{
                  margin: '0',
                  color: '#cccccc',
                  fontSize: '14px',
                  lineHeight: '1.6'
                }}>{orderDetails.steps_to_redeem_coupon}</p>
              </div>
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default OrderDetails; 